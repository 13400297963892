var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-def flex-cCenter flex-zCenter",staticStyle:{"max-width":"800px","padding-bottom":"2rem","margin-top":"2rem","font-size":"1rem","position":"relative"}},[_c('div',{staticClass:"mode-item active y-pointer flex-zTopBottom flex-def flex-zCenter flex-cCenter",on:{"click":function($event){return _vm.modeChoose(1)}}},[_vm._m(0),_vm._m(1)]),(_vm.addons.video && !_vm.is_tpl)?_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.modeChoose(2)}}},[_vm._m(2),_c('div',{staticStyle:{"height":"2rem"}},[_vm._v("短视频模式")])]):_vm._e(),(_vm.addons.classic)?_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.modeChoose(3)}}},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.addons.group_buy)?_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.modeChoose(4)}}},[_vm._m(5),_c('div',{staticStyle:{"height":"2rem"}},[_vm._v("拼团模式")])]):_vm._e(),(_vm.addons.free)?_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.modeChoose(5)}}},[_vm._m(6),_c('div',{staticStyle:{"height":"2rem"}},[_vm._v("免费模式")])]):_vm._e(),(_vm.addons.cdkey)?_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.modeChoose(6)}}},[_vm._m(7),_c('div',{staticStyle:{"height":"2rem"}},[_vm._v("激活码模式")])]):_vm._e(),_c('div',{staticClass:"mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom",on:{"click":function($event){return _vm.$message.error('增值插件正在规划中')}}},[_vm._m(8),_c('div',{staticStyle:{"height":"2rem"}},[_vm._v("投票模式")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon active flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-wind-power"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","height":"2rem"}},[_vm._v("通用模式 "),_c('br'),_vm._v(" (联盟模式)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-video-camera"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-s-management"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"2rem","text-align":"center"}},[_vm._v("单/多商家 "),_c('br'),_vm._v("极差模式")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-c-scale-to-original"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-shopping-cart-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-set-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mode-icon flex-def flex-zCenter flex-cCenter"},[_c('i',{staticClass:"el-icon-document-checked"})])
}]

export { render, staticRenderFns }